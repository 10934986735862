import React from 'react';
import styled from 'styled-components';
import {Modal, Button, message} from 'antd';
import nft from '../../Utils/Nft';
import {Close} from '@styled-icons/evil/Close';
import * as AppActions from '../../AppActions';
import * as ModalContent from './ModalContent';
import {CurrMintSupply} from '../../Components/CurrMint';
import {useOutlet} from 'reconnect.js';
import * as ErrorHandler from '../../Components/Error';

function Admin(props) {
  const [updateCnt, setUpdateCnt] = useOutlet('update-counter');
  const [txHistory] = useOutlet('tx-history');
  const [modalType, setModalType] = React.useState(null);

  const forceUpdate = React.useCallback(async () => {
    // "loadFullCOntractInfo" is slow, but since we're in admin, perf is not out top priority
    await nft.loadFullContractInfo();
    setUpdateCnt((cnt) => cnt + 1);
  }, [setUpdateCnt]);

  React.useEffect(() => {
    if (nft.isInitialized()) {
      forceUpdate();
    }
  }, [modalType, forceUpdate]);

  if (!nft.account) {
    return (
      <Wrapper>
        <div className="content">
          <h2 style={{fontSize: '1.6rem'}}>Admin</h2>

          <AccountInfo style={{marginTop: 40}}>
            <Button
              style={{alignSelf: 'center', width: 250}}
              type="primary"
              onClick={async () => {
                if (ErrorHandler.handleGlobalError()) {
                  return;
                }

                AppActions.setLoading(true);
                await AppActions.delay(500);
                try {
                  await nft.connect();
                  forceUpdate();
                } catch (ex) {
                  console.warn(ex);
                }
                AppActions.setLoading(false);
              }}>
              {updateCnt === 0 ? 'LOADING...' : 'CONNECT'}
            </Button>
          </AccountInfo>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="content">
        <h2 style={{fontSize: '1.6rem'}}>Admin</h2>

        <AccountInfo>
          <h3>Connected Account:</h3>
          <h4>帳戶: {nft.account || '---'}</h4>
          <h4>
            餘額:{' '}
            {nft.accountBalance ? nft.accountBalance / Math.pow(10, 18) : '---'}
          </h4>
        </AccountInfo>

        <h2>Contract Info</h2>

        <ContractInfo>
          <h3>Current Num: {nft.dataNumber || '---'}</h3>
          <h3>
            Contract Balance:{' '}
            {nft.contractBalance
              ? nft.contractBalance / Math.pow(10, 18)
              : '---'}
          </h3>
          <h3>Drawer address: {nft.drawerAddress || '---'}</h3>
        </ContractInfo>

        <h2>Actions</h2>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <EditInfo>
            <h3>Drawer</h3>
            <Button onClick={() => setModalType('edit-drawer')} type="primary">
              修改
            </Button>
          </EditInfo>
          <EditInfo>
            <h3>Withdraw</h3>
            <Button onClick={() => setModalType('withdraw')} type="primary">
              提取
            </Button>
          </EditInfo>
        </div>
      </div>

      <div className="bottom-list">
        <div className="content">
          <div style={{display: 'flex', alignItems: 'center'}}>
            <h3 style={{flex: 1}}>已發送之TX紀錄:</h3>
            <Button onClick={() => nft.clearTxHistory()}>全部清除</Button>
          </div>
          {txHistory.length === 0 && <div>無紀錄</div>}
          <ul>
            {txHistory
              .filter((record) => record.from === nft.account)
              .map((record) => (
                <li key={record.tx}>
                  {record.method} @ {record.tx}
                </li>
              ))}
          </ul>
        </div>
      </div>

      <Modal
        destroyOnClose={true}
        title={null}
        footer={null}
        bodyStyle={{padding: 0}}
        width={600}
        visible={!!modalType}
        closable={false}>
        <div style={{padding: 20}}>
          {modalType === 'withdraw' && (
            <ModalContent.TriggerContract
              contractMethodName="withdraw"
              header="提取收益至 drawer"
            />
          )}

          {modalType === 'edit-drawer' && (
            <ModalContent.EditContractAddress
              // nftFieldName="drawer"
              contractMethodName="setDrawer"
              header="設定Drawer"
            />
          )}
        </div>

        <Close
          size={20}
          onClick={() => setModalType(null)}
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            cursor: 'pointer',
          }}
        />
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 0px;
  background-color: #eee;
  min-height: 100vh;

  & > .content {
    margin: 20px auto;
    max-width: 720px;
    & > h2 {
      text-align: center;
      margin-top: 30px;
    }
  }

  & > .bottom-list {
    background-color: #ccc;
    padding: 20px;
    & > .content {
      margin: 20px auto;
      max-width: 720px;
    }
  }
`;

const SectionCard = styled.div`
  margin: 20px 0;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: all 200ms;

  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.6);
  }
`;

const ContractInfo = styled(SectionCard)``;
const AccountInfo = styled(SectionCard)``;

const EditInfo = styled(SectionCard)`
  width: 300px;
  margin: 15px;
  & > h3 {
    font-size: 0.4rem;
    color: gray;
  }

  & > h4 {
    font-size: 1.1rem;
    font-weight: 300;
  }

  & > button {
    align-self: flex-end;
  }

  &:hover > h4 {
    font-weight: 800;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
  }
`;

export default Admin;
