import React from "react";
import { Button } from "antd";
import nft from "../../Utils/Nft";
import * as AppActions from "../../AppActions";
 

export function TriggerContract(props) {
  const {nftFieldName, contractMethodName, header} = props;
  const [txHash, setTxHash] = React.useState(null);

  async function callContractMethod() {
    AppActions.setLoading(true);
    const resp = await nft[contractMethodName]();
    AppActions.setLoading(false);
    if (resp) {
      setTxHash(resp.transactionHash);
    }
  }
  return (
    <div>
      <h3>{header}</h3>

      {!txHash ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
          <Button onClick={callContractMethod}>執行</Button>
        </div>
      ) : (
        <div>TX Hash {txHash}</div>
      )}
    </div>
  );
}

export function EditContractAddress(props) {
  const { nftFieldName, contractMethodName, header, multiple = false } = props;
  const [txHash, setTxHash] = React.useState(null);
  const [expand, setExpand] = React.useState(false);
  const inputRef = React.useRef();

  async function callContractMethod() {
    let nextValue = inputRef.current.value;

    if (multiple) {
      nextValue = nextValue.split("\n").filter((item) => !!item);
    }

    if (nextValue) {
      AppActions.setLoading(true);
      const resp = await nft[contractMethodName](nextValue);
      AppActions.setLoading(false);
      if (resp) {
        setTxHash(resp.transactionHash);
      }
    }
  }

  return (
    <div>
      <h3>{header}</h3>
      {nftFieldName && !multiple && <div>value: {nft[nftFieldName]}</div>}

      {nftFieldName && multiple && (
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>目前設定</div>
            <Button type="link" onClick={() => setExpand(!expand)}>
              {expand ? "收合" : "展開"}
            </Button>
          </div>
          {expand && (
            <div>
              {Array.isArray(nft[nftFieldName]) &&
                nft[nftFieldName].map((addr, idx) => (
                  <div key={idx}>{addr}</div>
                ))}
            </div>
          )}
        </div>
      )}

      <textarea
        style={{ width: "100%", height: multiple ? 250 : 40 }}
        ref={inputRef}
      />

      {multiple && (
        <p style={{ fontSize: "0.8rem", color: "darkgray" }}>
          * 格式為每個address一行
        </p>
      )}

      {!txHash ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button onClick={callContractMethod}>送出</Button>
        </div>
      ) : (
        <div>TX Hash {txHash}</div>
      )}
    </div>
  );
}

